<template>
    <div v-if="OpenerCards.length !== 0" class="MultipleThreeCounsellorsOpener absolute allw allh flex warp">
        <div v-for="(item,index) in OpenerName" :key="index" class="flex">
            <div class="CardName flex items-center content-center flex1">
                <img :src="'./img/Cattle/'+ item + (LangeuageAgent === 'CH' ? '' : LangeuageAgent) +'.png'">
            </div>
            <div class="flex CardsBox allh items-center">
                <div class="flex1 relative flex content-center items-center">
                    <img v-for="(l,i) in OpenerCards[index+1]" v-show="l!==0" :key="i"
                         :src="'./img/puke/'+l+'.png'">
                    <div :class="{'BGH':index === OpenerPosition}"
                         class="CurrentResultBox absolute allw allh font24 colorfff flex items-center content-center">
                        {{OpenerCattleType[index]}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'MultipleThreeCounsellorsOpener',
        props: ['TableInfo'],
        computed: {
            ...mapState(['LangeuageAgent'])
        },
        data() {
            return {
                OpenerName: ['kp_01', 'kp_02', 'kp_03', 'kp_04'],
                OpenerPosition: '',  //开牌定位
                OpenerCattleType: ['', '', '', ''],
                OpenerCards: []
            }
        },
        created() {
            this.$nextTick(() => {
                this.Init(this.TableInfo, this.TableInfo);
            });
        },
        watch: {
            'TableInfo': {
                handler(n, o) {
                    this.Init(n, o);
                }, deep: true
            }
        },
        methods: {
            Init(n, o) {
                if (!n.cards) {
                    this.OpenerCards = [];
                    this.OpenerCattleType = ['', '', '', ''];
                    return;
                }
                this.OpenerCards = this.Clone(n.cards);
                let [a, b, c, d, e] = this.OpenerCards;
                let _a = [];
                let _b = [];
                let _c = [];
                let _d = [];
                let _e = [];
                let ToStringify = (obj) => {
                    return JSON.stringify(obj);
                };
                if (o.cards) [_a, _b, _c, _d, _e] = o.cards;
                if (a[0]) { //定位牌
                    // let serial = {
                    //     '0': '由庄家开始发牌',
                    //     '1': '由闲一开始发牌',
                    //     '2': '由闲二开始发牌',
                    //     '3': '由闲三开始发牌'
                    // };
                    let result = a[0] % 13 % 4;
                    this.OpenerPosition = result; //计算定位牌
                    // if (ToStringify(a) !== ToStringify(_a)) this.SetAudio(serial[result]);
                }
                let ThreeCounsellorsType = {
                    '0': '0点',
                    '1': '1点',
                    '2': '2点',
                    '3': '3点',
                    '4': '4点',
                    '5': '5点',
                    '6': '6点',
                    '7': '7点',
                    '8': '8点',
                    '9': '9点',
                    '10': '三公',
                };
                let TransLang = {
                    '0': '0 điểm',
                    '1': '1 điểm',
                    '2': '2 điểm',
                    '3': '3 điểm',
                    '4': '4 điểm',
                    '5': '5 điểm',
                    '6': '6 điểm',
                    '7': '7 điểm',
                    '8': '8 điểm',
                    '9': '9 điểm',
                    '10': 'Sâm lốc',
                };
                b = b.filter(item => item !== 0);
                c = c.filter(item => item !== 0);
                d = d.filter(item => item !== 0);
                e = e.filter(item => item !== 0);
                if (b.length === 3) {//庄
                    let type = this.CountThreeCounsellors(b);
                    if (this.LangeuageAgent === 'CH') {
                        this.OpenerCattleType[0] = ThreeCounsellorsType[type];
                    } else {
                        this.OpenerCattleType[0] = TransLang[type];
                    }
                    // if (ToStringify(b) !== ToStringify(_b) && _b.length !== 0) this.SetAudio(`庄牛,${CattleType[type]}`);
                }
                if (c.length === 3) {//闲1
                    let type = this.CountThreeCounsellors(c);
                    if (this.LangeuageAgent === 'CH') {
                        this.OpenerCattleType[1] = ThreeCounsellorsType[type];
                    } else {
                        this.OpenerCattleType[1] = TransLang[type];
                    }
                    // if (ToStringify(c) !== ToStringify(_c) && _c.length !== 0) this.SetAudio(`闲一,${CattleType[type]}`);
                }
                if (d.length === 3) {//闲2
                    let type = this.CountThreeCounsellors(d);
                    if (this.LangeuageAgent === 'CH') {
                        this.OpenerCattleType[2] = ThreeCounsellorsType[type];
                    } else {
                        this.OpenerCattleType[2] = TransLang[type];
                    }
                    // if (ToStringify(d) !== ToStringify(_d) && _d.length !== 0) this.SetAudio(`闲二,${CattleType[type]}`);
                }
                if (e.length === 3) {//闲3
                    let type = this.CountThreeCounsellors(e);
                    if (this.LangeuageAgent === 'CH') {
                        this.OpenerCattleType[3] = ThreeCounsellorsType[type];
                    } else {
                        this.OpenerCattleType[3] = TransLang[type];
                    }
                    // if (ToStringify(e) !== ToStringify(_e) && _e.length !== 0) this.SetAudio(`闲三,${CattleType[type]}`);
                }
            },
            CountThreeCounsellors(_cards) { //计算三公点数
                let cards = [];
                _cards.forEach((item, index) => {
                    if (item % 13 > 10) {
                        item = 10;
                    } else {
                        item = item % 13;
                    }
                    cards.push(item);
                });
                let [a, b, c] = cards;
                if (a === b && a === c) return '10'; //三公 两两相等 或者全是花牌
                return (a + b + c) % 10;
            }
        }
    }
</script>

<style lang="less">
    .MultipleThreeCounsellorsOpener {
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .5);
        z-index: 10;

        > div {
            width: 50%;
            height: 50%;

            .CardName {
                img {
                    height: .23rem;
                }
            }

            .CardsBox {
                width: 1.5rem;
                margin: 0 .1rem;

                > div {
                    height: .6rem;
                }

                img {
                    width: .35rem;
                    height: .47rem;
                    margin: 0 .025rem;
                }

                .CurrentResultBox {
                    background-color: rgba(62, 127, 233, .3);

                    &.BGH {
                        background-color: rgba(207, 22, 20, .3) !important;
                    }
                }
            }


        }
    }
</style>
